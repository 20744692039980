import React from "react";
import { ProviderCollection } from "./contexts/ProviderCollection";
import { Container, Typography } from "@mui/material";
import {
    DataGrid,
    GridRowsProp,
    GridColDef,
    GridValueFormatterParams,
} from "@mui/x-data-grid";
import { formatCurrency } from "./utils";

const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    { field: "item", headerName: "Item" },
    {
        field: "price",
        headerName: "Price",
        type: "number",
        valueFormatter: ({ value }: GridValueFormatterParams<number>) => {
            if (value == null) {
                return "";
            }
            return formatCurrency(value);
        },
    },
    {
        field: "quantity",
        headerName: "Quantity",
        type: "number",
    },
    {
        field: "personA",
        headerName: "Person A",
        type: "number",
        editable: true,
    },
    {
        field: "personB",
        headerName: "Person B",
        type: "number",
        editable: true,
    },
    {
        field: "personC",
        headerName: "Person C",
        type: "number",
        editable: true,
    },
    {
        field: "pricePerShare",
        headerName: "Price/share",
        type: "number",
        valueGetter: (params) => {
            return (
                (params.row.price * params.row.quantity) /
                (params.row.personA + params.row.personB + params.row.personC)
            );
        },
        valueFormatter: ({ value }: GridValueFormatterParams<number>) => {
            if (value == null) {
                return "";
            }
            return formatCurrency(value);
        },
    },
];

const rows: GridRowsProp = [
    {
        id: 1,
        item: "Thing 1",
        price: 15.6,
        quantity: 3,
        personA: 0,
        personB: 0,
        personC: 0,
    },
    {
        id: 2,
        item: "Thing 2",
        price: 15.6,
        quantity: 3,
        personA: 0,
        personB: 0,
        personC: 0,
    },
];

const App = () => {
    return (
        <Container>
            <ProviderCollection>
                <header>
                    <Typography variant="h1">Bill Splitter</Typography>
                </header>
                <main>
                    <section>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            experimentalFeatures={{ columnGrouping: true }}
                            columnGroupingModel={[
                                {
                                    groupId: "Receipt",
                                    children: [
                                        { field: "item" },
                                        { field: "price" },
                                        { field: "quantity" },
                                    ],
                                },
                                {
                                    groupId: "People",
                                    children: [
                                        { field: "personA" },
                                        { field: "personB" },
                                        { field: "personC" },
                                    ],
                                },
                                {
                                    groupId: "Calculation",
                                    children: [
                                        {
                                            field: "pricePerShare",
                                        },
                                    ],
                                },
                            ]}
                        />
                    </section>
                </main>
            </ProviderCollection>
        </Container>
    );
};

export default App;
