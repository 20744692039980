import React from "react";
import { initializeApp } from "firebase/app";
import { AuthProvider } from "./AuthContext";
// import { ThemeProvider } from "@mui/material/styles";
// import { useTheme } from "../theme";
import { SnackbarProvider } from "./SnackbarContext";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import firebaseConfig from "../config/firebaseConfig";

initializeApp(firebaseConfig);

if (process.env.NODE_ENV === "development") {
    const auth = getAuth();
    connectAuthEmulator(auth, `http://${window.location.hostname}:9099`);

    const db = getFirestore();
    connectFirestoreEmulator(db, window.location.hostname, 8080);
}

interface IProviderCollection {
    children?: React.ReactNode;
}

export const ProviderCollection: React.FC<IProviderCollection> = ({
    children,
}) => {
    // const { theme } = useTheme();
    return (
        // <ThemeProvider theme={theme}>
        <SnackbarProvider>
            <AuthProvider>{children}</AuthProvider>
        </SnackbarProvider>
        // </ThemeProvider>
    );
};
