import React from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { SnackbarContext } from "./SnackbarContext";

interface IAuthContext {
    isSignedIn: boolean;
    currentUser?: User;
    isLoadingAuth: boolean;
}

export const AuthContext = React.createContext<IAuthContext>({
    isSignedIn: false,
    currentUser: undefined,
    isLoadingAuth: true,
});

interface IAuthProvider {
    children?: React.ReactNode;
}

export const AuthProvider: React.FC<IAuthProvider> = ({ children }) => {
    const { pushSnackbar } = React.useContext(SnackbarContext);
    const [isSignedIn, setIsSignedIn] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState<User>();
    const [isLoadingAuth, setIsLoadingAuth] = React.useState(true);

    const auth = getAuth();

    React.useEffect(() => {
        if (isSignedIn) {
            pushSnackbar({
                message: "Signed in",
                severity: "success",
            });
        } else if (!isSignedIn && !isLoadingAuth) {
            pushSnackbar({
                message: "Signed out",
                severity: "success",
            });
        }
    }, [isLoadingAuth, isSignedIn, pushSnackbar]);

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setIsSignedIn(true);
            setCurrentUser(user);
        } else {
            setIsSignedIn(false);
            setCurrentUser(undefined);
        }
        setIsLoadingAuth(false);
    });

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                isSignedIn,
                isLoadingAuth,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
